import React, { useState } from 'react'
import { NavbarWrapper } from './styled'
import { ROUTE_PATH } from '../../routes/route.constant'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import ModalChangePass from '../../pages/ModalChangePass/ModalChangePass'
const Navbar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const titlePage = () => {
    switch (location.pathname) {
      case '/':
      case ROUTE_PATH.USER_MANAGEMENT:
        return <div className="text-header">ユーザー管理</div>
      case ROUTE_PATH.FILE_MANAGEMENT:
        return <div className="text-header">ファイル管理</div>
      case ROUTE_PATH.REGISTER_ADMIN:
        return <div className="text-header">管理者登録</div>
      case ROUTE_PATH.ORG_MANAGEMENT:
        return <div className="text-header">団体マスタ管理</div>
      case ROUTE_PATH.QUESTIONS_MANAGEMENT:
        return <div className="text-header">トレーニング管理</div>
      case ROUTE_PATH.PERSONAL_RESULTS:
        return <div className="text-header">個人結果</div>
      case ROUTE_PATH.LINK_MANAGEMENT:
        return <div className="text-header">解説動画管理</div>
      default:
        break
    }
  }
  const segments = location.pathname.split('/')
  const detailSegment = segments[1]
  const personalTitle = localStorage.getItem('NameTitle')

  const [isOpenModal, setIsOpenModal] = useState(false)
  const handleChangPass = () => {
    setIsOpenModal(true)
  }
  return (
    <NavbarWrapper>
      <div className="admin">
        <div style={{ cursor: 'pointer' }}>
          <img
            onClick={handleChangPass}
            src={process.env.PUBLIC_URL + '/images/Admin.png'}
            width={'40px'}
            alt=""
          />
        </div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            localStorage.clear()
            navigate(ROUTE_PATH.LOGIN)
          }}
        >
          <img
            src={process.env.PUBLIC_URL + '/images/Logout.png'}
            width={'24px'}
            alt=""
          />
        </div>
      </div>
      {titlePage()}
      {detailSegment === 'personal-analysis-detail' && (
        <>
          <div className="text-header">
            <ArrowLeftOutlined
              onClick={() => {
                navigate(ROUTE_PATH.PERSONAL_RESULTS)
              }}
            />
            {personalTitle}
          </div>
        </>
      )}
      {isOpenModal && (
        <ModalChangePass
          isOpen={isOpenModal}
          onCancel={() => {
            setIsOpenModal(false)
          }}
          onOk={() => {
            setIsOpenModal(false)
          }}
        />
      )}
    </NavbarWrapper>
  )
}

export default Navbar
