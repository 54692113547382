import React from 'react'
import { ButtonStyled } from './styled'
import { checkPropTypes } from 'prop-types'

const Button = React.memo(props => {
  const { onClick } = props
  return <ButtonStyled onClick={onClick} {...props}></ButtonStyled>
})

Button.propTypes = {
  onClick: checkPropTypes.func,
}

Button.defaultProps = {
  onClick: null,
}

export default Button
