import axios from 'axios'
import { ACCESS_TOKEN_KEY } from '../constants/init.constants'
import { getAccessToken } from '../constants/utils'
import { toast } from 'sonner'

const token = window.localStorage.getItem(ACCESS_TOKEN_KEY)

const http = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    Authorization: token ? `Bearer ${token}` : '',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
})

http.interceptors.request.use(
  async config => {
    const token = getAccessToken() ?? ''
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      }
    }
    return config
  },
  error => Promise.reject(error),
)

http.interceptors.response.use(
  response => {
    if (response.data) {
      return response.data
    }
  },
  error => {
    return toast.error(error.response.data.message)
  },
)

export default http
