// Auth
export const apiLogin = 'auth/sign-in'
export const apiForgotPassword = 'auth/forgot-password'
export const apiUpdatePassword = 'auth/update-password-admin'

// User
export const apiGetUser = 'users'
export const apiExtendUser = 'users/date-expiry'
export const apiImportUser = 'users/import'
// File
export const apiGetFile = 'files'
//adminManagement
export const apiGetAdmin = 'admins'
//OrgManagement
export const apiGetOrg = 'organizations'
//QuestionManagement
export const apiQuestion = 'question'
//mini-game
export const apiminigameQuestion = 'question/mini-game'
//mini-game
export const apiPersonalDetail = 'individual-results'
//abilityDetail
export const apiAbilityDetail = 'abilities'
//link Management
export const apiLinkMangement = 'settings'
