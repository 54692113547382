import React, { useEffect, useState } from 'react'
import { SidebarWrapper } from './styled'
import { Menu } from 'antd'
import { ROUTE_PATH } from '../../routes/route.constant'
import { Link, useLocation } from 'react-router-dom'
import {
  ApartmentOutlined,
  AuditOutlined,
  ControlOutlined,
  FileTextOutlined,
  LinkOutlined,
  ReadOutlined,
  TeamOutlined,
} from '@ant-design/icons'
const rootSubmenuKeys = [
  {
    tabKey: 'sub1',
    tabPath: ROUTE_PATH.USER_MANAGEMENT,
  },
  {
    tabKey: 'sub2',
    tabPath: ROUTE_PATH.FILE_MANAGEMENT,
  },
  {
    tabKey: 'sub3',
    tabPath: ROUTE_PATH.REGISTER_ADMIN,
  },
  {
    tabKey: 'sub4',
    tabPath: ROUTE_PATH.ORG_MANAGEMENT,
  },
  {
    tabKey: 'sub5',
    tabPath: ROUTE_PATH.QUESTIONS_MANAGEMENT,
  },
  {
    tabKey: 'sub6',
    tabPath: ROUTE_PATH.PERSONAL_RESULTS,
  },
]
const Sidebar = () => {
  const location = useLocation()
  const segments = location.pathname.split('/')
  const detailResult = segments[1]
  const [keySelected, setKeySelected] = useState('')
  // const [openKeys, setOpenKeys] = useState([])
  const [role, setRole] = useState(null)
  useEffect(() => {
    const storedUser = localStorage.getItem('accessToken')
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser).user.role
      setRole(parsedUser)
    }
  }, [])

  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    }
  }

  const items = [
    getItem(
      <Link to={ROUTE_PATH.USER_MANAGEMENT}>
        <TeamOutlined style={{ fontSize: '20px', marginRight: '8px' }} />
        ユーザー管理
      </Link>,
      'sub1',
    ),
    getItem(
      <Link to={ROUTE_PATH.REGISTER_ADMIN}>
        <ControlOutlined style={{ fontSize: '20px', marginRight: '8px' }} />
        管理者登録
      </Link>,
      'sub3',
    ),
    getItem(
      <Link to={ROUTE_PATH.ORG_MANAGEMENT}>
        <ApartmentOutlined style={{ fontSize: '20px', marginRight: '8px' }} />
        団体マスタ管理
      </Link>,
      'sub4',
    ),
    getItem(
      <Link to={ROUTE_PATH.QUESTIONS_MANAGEMENT}>
        <ReadOutlined style={{ fontSize: '20px', marginRight: '8px' }} />
        トレーニング管理
      </Link>,
      'sub5',
    ),
    getItem(
      <Link to={ROUTE_PATH.FILE_MANAGEMENT}>
        <FileTextOutlined style={{ fontSize: '20px', marginRight: '8px' }} />
        ファイル管理
      </Link>,
      'sub2',
    ),
    getItem(
      <Link to={ROUTE_PATH.PERSONAL_RESULTS}>
        <AuditOutlined style={{ fontSize: '20px', marginRight: '8px' }} />
        個人結果
      </Link>,
      'sub6',
    ),
    getItem(
      <Link to={ROUTE_PATH.LINK_MANAGEMENT}>
        <LinkOutlined style={{ fontSize: '20px', marginRight: '8px' }} />
        解説動画管理
      </Link>,
    ),
  ]
  const itemsRoleGroupAdmin = [
    getItem(
      <Link to={ROUTE_PATH.PERSONAL_RESULTS}>
        <AuditOutlined style={{ fontSize: '20px', marginRight: '8px' }} />
        個人結果
      </Link>,
      'sub6',
    ),
  ]

  useEffect(() => {
    const matchingItem = rootSubmenuKeys.find(
      item => item.tabPath === location.pathname,
    )
    const key = matchingItem ? matchingItem.tabKey : ''
    if (key) {
      setKeySelected(key)
    }
  }, [location])

  return (
    <SidebarWrapper>
      <div style={{ padding: '20px 40px 20px 50px' }}>
        <img
          src={process.env.PUBLIC_URL + '/images/MetaVision_Logo.png'}
          width={'121px'}
          height={'62px'}
          alt=""
        />
      </div>
      <Menu
        mode="inline"
        selectedKeys={
          detailResult === 'personal-analysis-detail' ? ['sub6'] : [keySelected]
        }
        // openKeys={openKeys}
        items={role === 'Admin' ? items : itemsRoleGroupAdmin}
        // onOpenChange={handleOpenChange}
        onClick={e => {
          setKeySelected(e.key)
        }}
      />
    </SidebarWrapper>
  )
}

export default Sidebar
