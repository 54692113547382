import { Col, Form, Input, Row, Spin } from 'antd'
import Button from '../../components/Button'
import ModalCommon from '../../components/ModalCommon'
import { isValidPassword } from '../../constants/utils'
import { useState } from 'react'
import AuthServices from '../../services/AuthServices'
import { toast } from 'sonner'

const ModalChangePass = ({ isOpen, onCancel, onOk }) => {
  const [form] = Form.useForm()
  const getInfoAdmin = JSON.parse(localStorage.getItem('accessToken'))
  const nameAdmin = getInfoAdmin.user.name
  const emailAdmin = getInfoAdmin.user.email
  const [loading, setLoading] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')

  const handlePasswordChange = e => {
    if (confirmPassword) {
      form.validateFields(['confirmPass'])
    }
  }

  const handleConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value)
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async values => {
        try {
          const bodyData = {
            oldPassword: values.oldPassword,
            newPassword: values.password,
          }
          setLoading(true)
          const res = await AuthServices.updatePassword(null, bodyData)

          if (res.statusCode === 200 || res.statusCode === 201) {
            toast.success('パスワードが無事に変更されました。')
            onOk()
          }
        } catch (error) {
          toast.error(error.response.data.message)
        } finally {
          setLoading(false)
        }
      })
      .catch(error => {
        // toast.error(error.message)
      })
  }

  return (
    <ModalCommon
      title="管理者の情報"
      open={isOpen}
      width="740px"
      onOk={onOk}
      onCancel={onCancel}
      footer={
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}
        >
          <Button
            onClick={handleSubmit}
            bg_color="#FF9C33"
            color="white"
            style={{ width: '120px' }}
          >
            決定
          </Button>
        </div>
      }
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          labelAlign="left"
          labelWrap
          colon={false}
          autoComplete="off"
          initialValues={{
            fullname: nameAdmin,
            email: emailAdmin,
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item labelCol={{ span: 7 }} label="名前" name={'fullname'}>
                <Input readOnly disabled={true} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 7 }}
                label="メールアドレス"
                name={'email'}
              >
                <Input readOnly disabled={true} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 7 }}
                label="以前のパスワード"
                name={'oldPassword'}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: '以前のパスワードフィールドは必須です',
                  },
                  {
                    pattern: isValidPassword(),
                    message: 'パスワードは6文字から14文字で入力してください',
                  },
                ]}
              >
                <Input.Password placeholder="以前のパスワード" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 7 }}
                label="新しいパスワード"
                name={'password'}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: '新しいパスワードフィールドは必須です',
                  },
                  {
                    pattern: isValidPassword(),
                    message: 'パスワードは6文字から14文字で入力してください',
                  },
                ]}
              >
                <Input.Password
                  placeholder="新しいパスワード"
                  onChange={handlePasswordChange}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 7 }}
                label="新パスワード（確認)"
                name={'confirmPass'}
                rules={[
                  {
                    required: true,
                    message: '新しいパスワードフィールドは必須です',
                  },
                  {
                    pattern: isValidPassword(),
                    message: 'パスワードは6文字から14文字で入力してください',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const newPassword = getFieldValue('password')

                      if (
                        !value ||
                        newPassword === value ||
                        value.length < 6 ||
                        value.length > 14
                      ) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(
                          '入力された新パスワードと新パスワード（確認）が一致する必要があります。',
                        ),
                      )
                    },
                  }),
                ]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Input.Password
                  placeholder="新しいパスワード（確認用）"
                  onChange={handleConfirmPasswordChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </ModalCommon>
  )
}

export default ModalChangePass
