/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { AuthenticationWrapper, Container } from './styled'
import LoginForm from './LoginForm'
import ForgotPass from './ForgotPass'
import ConfirmEmail from './ConfirmEmail'
const Authentication = () => {
  const [isLogin, setIsLogin] = useState(0)
  const [isEmail, setIsEmai] = useState()
  const onToggle = () => {
    setIsLogin(isLogin + 1)
  }
  const onToggle1 = () => {
    setIsLogin(isLogin - 1)
  }
  const onToggle2 = () => {
    setIsLogin(0)
  }
  const onChangeEmail = value => {
    setIsEmai(value)
  }
  return (
    <Container>
      <AuthenticationWrapper>
        <div className="logo-app">
          <img
            src={process.env.PUBLIC_URL + '/images/Logo_login.png'}
            width={'100%'}
            alt=""
          />
        </div>

        {isLogin === 0 && <LoginForm onToggle={onToggle} />}
        {isLogin === 1 && (
          <ForgotPass
            onToggle={onToggle}
            onBack={onToggle1}
            onChangeEmail={onChangeEmail}
          />
        )}
        {isLogin === 2 && (
          <ConfirmEmail
            onToggle={onToggle}
            onBack={onToggle2}
            isEmail={isEmail}
          />
        )}
      </AuthenticationWrapper>
    </Container>
  )
}

export default Authentication
