import http from '.'
import { apiForgotPassword, apiLogin, apiUpdatePassword } from './apiRouter'

const login = body => http.post(apiLogin, body)
const sentEmailForgotPassword = (_, bodyData) => {
  return http.post(`${apiForgotPassword}`, bodyData)
}
const ResetPassword = (params, bodyData) => {
  return http.patch(`${apiForgotPassword}`, bodyData)
}
const updatePassword = (params, bodyData) => {
  return http.post(`${apiUpdatePassword}`, bodyData)
}
const AuthServices = {
  login,
  sentEmailForgotPassword,
  ResetPassword,
  updatePassword,
}

export default AuthServices
