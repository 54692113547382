import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import {
  isValidEmail,
  // isValidPassword,
  validateNoEmoji,
  validatePasswordEmoji,
} from '../../constants/utils'
import { ContainerForm } from './styled'
import { ACCESS_TOKEN_KEY } from '../../constants/init.constants'
import { useNavigate } from 'react-router-dom'
import AuthServices from '../../services/AuthServices'
import { toast } from 'sonner'
import { ROUTE_PATH } from '../../routes/route.constant'

const LoginForm = ({ onToggle }) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    form
      .validateFields()
      .then(val => {
        setLoading(true)
        const body = {
          email: val.email,
          password: val.password,
          role: 'Admin',
        }
        AuthServices.login(body)
          .then(res => {
            if (res.data) {
              toast.success('ログインに成功しました')
              localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(res.data))
              navigate(ROUTE_PATH.PERSONAL_RESULTS)
              onToggle()
            }
          })
          .finally(() => setLoading(false))
      })
      .catch(er => {})
  }
  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }
  return (
    <ContainerForm>
      <div className="text-welcome" style={{ paddingBottom: '60px' }}>
        おかえりなさい
      </div>
      <div>
        <Form form={form}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'メールアドレスを入力してください',
              },
              {
                pattern: isValidEmail(),
                message: 'メールアドレスのフォーマットが正しくありません',
              },
              {
                validator: validateNoEmoji,
              },
            ]}
            name={'email'}
          >
            <Input placeholder="メールアドレス" onKeyPress={handleKeyPress} />
          </Form.Item>
          <Form.Item
            rules={[
              // {
              //   required: true,
              //   message: 'パスワードを入力してください',
              // },
              // {
              //   pattern: isValidPassword(),
              //   message: 'パスワードは6文字以上で入力してください',
              // },
              // {
              //   validator: validateNoEmoji,
              // },
              {
                required: true,
                message: 'パスワードを入力してください',
              },
              {
                validator: validatePasswordEmoji,
              },
            ]}
            name={'password'}
          >
            <Input.Password
              placeholder="パスワード "
              onKeyPress={handleKeyPress}
            />
          </Form.Item>
        </Form>
        <div className="btn-forgot" onClick={onToggle}>
          パスワードを忘れた場合
        </div>
        <Button loading={loading} className="btn-login" onClick={handleSubmit}>
          ログイン
        </Button>
      </div>
    </ContainerForm>
  )
}

export default LoginForm
