export const ROUTE_PATH = {
  USER_MANAGEMENT: '/user-management',
  LOGIN: '/login',
  CHANGE_PASSWORD: '/reset-password',
  RESET_PASSWORD: '/reset-password-success',
  FILE_MANAGEMENT: '/file-management',
  REGISTER_ADMIN: '/admin-management',
  ORG_MANAGEMENT: '/organization-management',
  QUESTIONS_MANAGEMENT: '/questions-management',
  PERSONAL_RESULTS: '/personal-results',
  PERSONAL_ANALYSIS_DETAIL: '/personal-analysis-detail/:id',
  LINK_MANAGEMENT: '/link-management',
}
