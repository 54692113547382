import { Suspense, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../routes/route.constant'

const AppLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    if (location.pathname === '/') navigate(ROUTE_PATH.PERSONAL_RESULTS)
  })
  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  )
}

export default AppLayout
