import { Form, Input } from 'antd'
import React, { useState } from 'react'
import Button from '../../components/Button'
import ConfirmEmail from './ConfirmEmail'
import { isValidEmail, validateNoEmoji } from '../../constants/utils'
import { ContainerForm } from './styled'
import AuthServices from '../../services/AuthServices'

const ForgotPass = ({ onToggle, onBack, onChangeEmail }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [isConfirmEmail, setIsConfirmEmail] = useState(false)

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async values => {
        const bodyData = {
          email: values.email,
        }
        setLoading(true)

        try {
          const res = await AuthServices.sentEmailForgotPassword(null, bodyData)

          if (res.statusCode === 200 || res.statusCode === 201) {
            setIsConfirmEmail(true)
            onToggle()
          }
        } finally {
          setLoading(false)
        }
      })
      .catch(er => {})
  }

  const handleEmailChange = e => {
    const value = e.target.value
    onChangeEmail(value)
  }
  return (
    <ContainerForm>
      <div style={{ paddingBottom: '60px' }}>
        <div className="text-welcome">パスワード再設定</div>
        <div>
          <div>登録しているメールアドレスをご入力ください。</div>
          <div>新しいパスワードを設定するための案内をお送りします。</div>
        </div>
      </div>
      <div>
        <Form form={form}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'メールアドレスを入力してください',
              },
              {
                pattern: isValidEmail(),
                message: 'メールアドレスのフォーマットが正しくありません',
              },
              {
                validator: validateNoEmoji,
              },
            ]}
            name={'email'}
          >
            <Input placeholder="メールアドレス" onChange={handleEmailChange} />
          </Form.Item>
        </Form>
        <Button
          loading={loading}
          className="btn-login"
          style={{ margin: '24px 0' }}
          onClick={handleSubmit}
        >
          送信{''}
        </Button>
        <Button className="btn-back" onClick={onBack}>
          ログイン画面に戻る
        </Button>
      </div>
      {isConfirmEmail && <ConfirmEmail test={test} />}
    </ContainerForm>
  )
}

export default ForgotPass
