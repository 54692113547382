import { Empty } from 'antd'

export const ACCESS_TOKEN_KEY = 'accessToken'
export const ADMIN_INFO = 'Admin_Info'
export const DEFAULT_PAGE_SIZE = 10
export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100]
export const formatDateDefault = 'DD/MM/YYYY'
export const formatDateDisplay = 'YYYY/MM/DD'
export const CustomEmptyDescription = () => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    imageStyle={{ margin: '20px' }}
    description={
      <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>データがありません</span>
    }
  />
)
export const converStatus = status => {
  switch (status) {
    case 'Active':
      return 'アクティブ'
    default:
      return '非アクティブ'
  }
}

export const converRoleAdmin = role => {
  switch (role) {
    case 'Admin':
      return '事務局'
    default:
      return '団体担当者'
  }
}
export const converMainCategory = mainCategory => {
  switch (mainCategory) {
    case 'A':
      return 'A読解'
    case 'B':
      return 'B記憶'
    case 'C':
      return 'C認知'
    default:
      return 'D集中・注意'
  }
}
export const converColorB3 = color => {
  switch (color) {
    case 'YELLOW':
      return '黄色'
    case 'RED':
      return '赤'
    case 'VIOLET':
      return 'むらさき'
    case 'PINK':
      return 'ピンク'
    case 'GREEN':
      return 'きみどり'
    case 'BROWN':
      return '茶色'
    case 'BLACK':
      return '黒'
    case 'BLUE':
      return '青'
    default:
      return '白'
  }
}
export const converCategory = category => {
  switch (category) {
    case 'A1':
      return 'A1たんけん足し算'
    case 'A2':
      return 'A2あるのはどっち'
    case 'A3':
      return 'A3見て読む力'
    case 'B1':
      return 'B1しゅんかんきおく'
    case 'B2':
      return 'B2図形きおく'
    case 'B3':
      return 'B3いろいろタッチ'
    case 'C1':
      return 'C1立体図形'
    case 'C2':
      return 'C2同じ組み合わせ'
    case 'C3':
      return 'C3鏡にうつった絵'
    case 'D1':
      return 'D1いろいろ方向イラスト'
    case 'D2':
      return 'D2飛び出すボールの数'
    default:
      return 'D3かくれた図形さがし'
  }
}
export function filterListNo(item1, item2) {
  var listNo = item2.filter(item => !item1.includes(item))

  return listNo
}
export const extractPathQuestion = url => {
  if (url && typeof url === 'string') {
    const parts = url.split('/')
    const index = parts.findIndex(part => part === 'assets')
    if (index !== -1) {
      return parts.slice(index).join('/')
    }
  }
  return url
}
export function convertDataTableA2(data) {
  var keys = Object.keys(data)
  var numRows = data[keys[0]].length
  var result = []

  for (var i = 0; i < numRows; i++) {
    var newRow = {}
    var hasNull = false
    keys.forEach(function (key) {
      if (data[key][i] !== null) {
        newRow[key] = data[key][i]
      } else {
        hasNull = true
      }
    })
    if (!hasNull) {
      result.push(newRow)
    }
  }

  return result
}
