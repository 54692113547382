// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-container {
  background-color: #fff;
  height: 100%;
  border-radius: 12px;
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/layouts/MainLayout/layout.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;AACJ","sourcesContent":[".box-container {\n    background-color: #fff;\n    height: 100%;\n    border-radius: 12px;\n    padding: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
