import { Form } from 'antd'
import React, { useEffect, useState } from 'react'
import Button from '../../components/Button'
import AuthServices from '../../services/AuthServices'
import { toast } from 'sonner'
import { ContainerForm } from './styled'

const ConfirmEmail = ({ onBack, isEmail }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const [countdown, setCountdown] = useState(60)
  const [intervalId, setIntervalId] = useState(null)
  const handleSubmit = () => {
    form.validateFields().then(async values => {
      try {
        const bodyData = {
          email: isEmail,
        }
        setLoading(true)
        const res = await AuthServices.sentEmailForgotPassword(null, bodyData)

        if (res.statusCode === 200 || res.statusCode === 201) {
          toast.success('メールアドレスが再送信されました。')
          setDisableSubmit(true)
          startCountdown()
        }
      } catch (error) {
        toast.error(error.response.data.message)
      } finally {
        setLoading(false)
      }
    })
  }

  const hideEmail = email => {
    const parts = email.split('@')
    const username = parts[0]
    const domain = parts[1]

    const hiddenUsername = `${username.substring(0, 2)}****${username.slice(
      -1,
    )}`

    const hiddenDomain = `${domain.charAt(0)}*****${domain.slice(-1)}`
    return `${hiddenUsername}@${hiddenDomain}`
  }
  const hiddenEmail = hideEmail(isEmail)

  const startCountdown = () => {
    const id = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown === 0) {
          clearInterval(id)
          setDisableSubmit(false)
          return 60
        }
        return prevCountdown - 1
      })
    }, 1000)
    setIntervalId(id)
  }

  useEffect(() => {
    return () => {
      clearInterval(intervalId)
    }
  }, [intervalId])

  return (
    <ContainerForm>
      <div>
        <div className="text-welcome">メールアドレスをご確認ください。</div>
        <div>
          <div>
            パスワードリセットの案内を <span>{hiddenEmail}</span>{' '}
            のメールアドレス宛にお送りしました。
          </div>
          <div style={{ marginTop: '60px', marginBottom: '30px' }}>
          パスワードリセットの案内メールが届かない場合は、迷惑メールフォルダまたはプロモーションフォルダーをご確認ください。
          </div>
        </div>
      </div>
      <div>
        <Button
          loading={loading}
          className="btn-login"
          style={{ margin: '24px 0' }}
          onClick={handleSubmit}
          disabled={disableSubmit}
        >
          メールを再送信する
          {disableSubmit && <div>({countdown})</div>}
        </Button>
        <Button className="btn-back" onClick={onBack}>
          ログイン画面に戻る
        </Button>
      </div>
    </ContainerForm>
  )
}

export default ConfirmEmail
