// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageNotFound_title__LfIVK {
  margin-top: 50px;
  padding: 300px 0;
  text-align: center;
  font-size: 64px;
}
@media (max-width: 768px) {
  .PageNotFound_title__LfIVK {
    font-size: 24px;
    padding: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/PageNotFound/index.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;AACJ;AACI;EANJ;IAOM,eAAA;IACA,aAAA;EAEJ;AACF","sourcesContent":[".title {\n    margin-top: 50px;\n    padding: 300px 0;\n    text-align: center;\n    font-size: 64px;\n  \n    @media (max-width: 768px) {\n      font-size: 24px;\n      padding: 15px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `PageNotFound_title__LfIVK`
};
export default ___CSS_LOADER_EXPORT___;
