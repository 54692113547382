import React, { Suspense } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { ROUTE_PATH } from './route.constant'

import AppLayout from '../layouts/AppLayout'
import AuthLayout from '../layouts/AuthLayout'
import MainLayout from '../layouts/MainLayout'
import PageNotFound from '../pages/PageNotFound/PageNotFound'
import Authentication from '../pages/Authentication'
const ChangePassword = React.lazy(
  () => import('../pages/Authentication/ChangePassword'),
)
const ResetPasswordSuccess = React.lazy(
  () => import('../pages/Authentication/ResetPasswordSuccess'),
)
const UserManagement = React.lazy(() => import('../pages/UserManagement'))
const FileManagement = React.lazy(() => import('../pages/FileManagement'))
const RegisterAdmin = React.lazy(() => import('../pages/RegisterAdmin'))
const OrgManagement = React.lazy(() => import('../pages/OrgManagement'))
const PersonalResults = React.lazy(() => import('../pages/PersonalResults'))
const LinkManagement = React.lazy(() => import('../pages/LinkManagement'))
const PersonalAnalysisDetail = React.lazy(
  () => import('../pages/PersonalDetail'),
)
const QuestionManagement = React.lazy(
  () => import('../pages/QuestionManagement'),
)
export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <PageNotFound />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {
            path: ROUTE_PATH.LOGIN,
            element: (
              <Suspense fallback={null}>
                <Authentication />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.CHANGE_PASSWORD,
            element: (
              <Suspense fallback={null}>
                <ChangePassword />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.RESET_PASSWORD,
            element: (
              <Suspense fallback={null}>
                <ResetPasswordSuccess />
              </Suspense>
            ),
          },
        ],
      },
      {
        element: <MainLayout />,
        children: [
          {
            path: ROUTE_PATH.USER_MANAGEMENT,
            element: (
              <Suspense fallback={null}>
                <UserManagement />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.FILE_MANAGEMENT,
            element: (
              <Suspense fallback={null}>
                <FileManagement />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.REGISTER_ADMIN,
            element: (
              <Suspense fallback={null}>
                <RegisterAdmin />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.QUESTIONS_MANAGEMENT,
            element: (
              <Suspense fallback={null}>
                <QuestionManagement />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.ORG_MANAGEMENT,
            element: (
              <Suspense fallback={null}>
                <OrgManagement />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.PERSONAL_RESULTS,
            element: (
              <Suspense fallback={null}>
                <PersonalResults />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.PERSONAL_ANALYSIS_DETAIL,
            element: (
              <Suspense fallback={null}>
                <PersonalAnalysisDetail />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.LINK_MANAGEMENT,
            element: (
              <Suspense fallback={null}>
                <LinkManagement />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
])
