import styled from 'styled-components'
export const Container = styled.div`
  background: url('images/backgroundForm.png') center/cover no-repeat;
`

export const ContainerForm = styled.div`
  width: 546px;
  margin-top: -50px;
`
export const AuthenticationWrapper = styled.div`
  /* display: flex; */
  width: 546px;
  padding: 100px;
  /* flex-direction: column;
justify-content: center; */
  gap: 100px;
  font-family: 'Inter', sans-serif;
  margin: 0 auto;

  .logo-app {
    width: 458px;
    margin: 0 auto 100px;
  }

  .btn-back {
    width: 100%;
    color: #040308;
    font-size: 16px;
    font-weight: 600;
  }

  .text-welcome {
    color: #040308;

    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .btn-login {
    width: 100%;
    border-radius: 5px;
    background: #ff9c33;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px;
    height: fit-content;
  }

  .btn-sign-up,
  .btn-forgot {
    color: #ff9c33;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .btn-forgot {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
  }
`
export const StyledResetPasswordSuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 700px;
  margin-left: 33%;

  // gap: 100px;
  font-family: 'Inter', sans-serif;

  .btn-login {
    width: 100%;
    border-radius: 5px;
    background: #ff9c33;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px;
    height: fit-content;
  }
  .message {
    color: #040308;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin-top: 20px;

    line-height: normal;
  }
  .iconSuccess {
    display: flex;
    justify-content: center;
  }
  .iconSuccess > div {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: rgba(52, 168, 83, 0.05);
    border: none;
  }
`
