import styled from 'styled-components'

export const SidebarWrapper = styled.div`
  height: 100vh;
  .ant-menu-item.ant-menu-item-selected {
    color: #ff9c33;
    border-right: 2px solid #ff9c33;
    background: rgba(255, 156, 51, 0.1);
    font-weight: 700;
  }

  .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #ff9c33;
  }
  .ant-menu-item {
    border-radius: 0;
    margin: 0;
    width: 100%;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-menu-light :hover {
    color: rgba(0, 0, 0, 0.45);
  }

  .active-link {
    color: #ff9c33;
    font-weight: 700;
  }

  .inactive-link {
    color: inherit !important;
  }
`

export const NavbarWrapper = styled.div`
  background-color: #fff;
  .admin {
    display: flex;
    padding: 10px 24px;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }

  .text-header {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #ff9c33;
    padding: 10px 24px;
    font-family: 'Roboto';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    border-top: 1px solid #e6ebf1;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  }

  .sub-title {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #ff9c33;
  }

  .sub-title::before {
    content: '';
    display: inline-block;
    height: 4px;
    width: 4px;
    border-radius: 4px;
    background-color: #ff9c33;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 10px;
  }
`
export const FormMainLayout = styled.div`
  background-color: #fff;
  height: 100%;
  border-radius: 12px;
  padding: 20px;
`
